import AC from "./access_control";

function agregarRutaOther(item, array) {
  array.push({
    path: item.path,
    name: item.name,
    component: item.component,
    beforeEnter: (to, from, next) => {
      let granted = false;

      if (item.loginRequerido) {
        granted =
          AC.validateAccess(AC.CONDITIONS.authenticated) &&
          AC.validateAccess(AC.CONDITIONS.permisosValidos, to);
      } else if (item.noLoginRequerido) {
        granted = AC.validateAccess(AC.CONDITIONS.notAuthenticated);
      }

      granted ? next() : next("/");
    }
  });
}

function agregarRutaSidebar(item, array) {
  array.push({
    path: item.path,
    name: item.name,
    component: item.component,
    beforeEnter: (to, from, next) => {
      let granted =
        AC.validateAccess(AC.CONDITIONS.authenticated) &&
        AC.validateAccess(AC.CONDITIONS.permisosValidos, to);

      granted ? next() : next("/login/");
    }
  });
}

// importar otras rutas
function armarRoutes(otherRoutes, sidebarRoutes) {
  let routes = [];

  otherRoutes.forEach(item => {
    agregarRutaOther(item, routes);
  });

  // importar rutas del sidebar
  sidebarRoutes.forEach(item => {
    if (item.children == null) {
      agregarRutaSidebar(item, routes);
    } else {
      item.children.forEach(child => {
        agregarRutaSidebar(child, routes);
      });
    }
  });

  return routes;
}

export default armarRoutes;
