<template>
  <div style="display:flex">
    <div style="margin:auto; margin-right:0px">
      <el-button
        :loading="cargando"
        element-loading-spinner="el-icon-loading"
        element-loading-custom-class="loading-class"
        :type="type"
        @click="handleClick"
        :disabled="disabled || cargando"
        :class="{
          'boton-cargando': cargando && !soloIcono,
          'boton-cargando-soloicono': soloIcono,
        }"
        :size="size"
        :icon="iconClassIfActivo"
        :plain="plain"
      >
        <span v-text="textoActual"></span>
      </el-button>
    </div>
  </div>
</template>

<style>
.loading-class > .el-loading-spinner {
  top: 33px;
  right: 7px;
  width: 20px;
}
.boton-cargando > span {
  margin-left: -20px;
}
.boton-cargando-soloicono > .loading-class > div {
  right: auto;
  width: 100%;
}
</style>

<script>
export default {
  name: "boton-guardar",
  props: {
    // Funcion que el padre de este componente debe llamar para desbloquear el boton
    onSubmit: {
      type: Function,
      default: () => {},
    },
    // Funcion que se ejecuta antes del onSubmit cuando el boton se clickea
    preSubmit: {
      type: Function,
      default: () => {},
    },
    usarIcono: {
      type: Boolean,
      default: false,
    },
    texto: {
      type: String,
      default: "Guardar",
    },
    type: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    soloIcono: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "el-icon-check",
    },
    size: {
      type: String,
      default: "normal",
    },
    plain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClassIfActivo() {
      if (this.usarIcono) {
        return this.iconClass;
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      textoActual: "",
      iconoActivo: false,
      cargando: false,
    };
  },
  created() {
    if (!this.soloIcono) {
      this.textoActual = this.texto;
    }
    if (this.soloIcono || this.usarIcono) {
      this.iconoActivo = true;
    }
  },
  methods: {
    // Al hacer click en el boton, cambiar texto, mostrar icono de cargando
    // y deshabilitar
    handleClick() {
      if (!this.soloIcono) {
        this.textoActual = "   Cargando...";
      }
      if (this.soloIcono || this.usarIcono) {
        this.iconoActivo = false;
      }

      this.cargando = true;

      this.preSubmit();

      // Ejecutar prop onSubmit, dandole la funcion afterSubmit como parámetro
      this.onSubmit((_) => {
        if (!this.soloIcono) {
          this.textoActual = this.texto;
        }
        if (this.soloIcono || this.usarIcono) {
          this.iconoActivo = true;
        }

        this.cargando = false;
      });
    },
  },
};
</script>
