var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "sidebarMenuButton" } }, [
    _vm.$store.state.sidebar
      ? _c(
          "div",
          { attrs: { id: "ocultarSidebar" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "text" },
                on: {
                  click: function($event) {
                    return _vm.$store.commit("ocultarSidebar")
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "icon-menu",
                  staticStyle: { color: "#00BFA5" }
                })
              ]
            )
          ],
          1
        )
      : _vm._e(),
    !_vm.$store.state.sidebar
      ? _c(
          "div",
          { attrs: { id: "mostrarSidebar" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "text" },
                on: {
                  click: function($event) {
                    return _vm.$store.commit("mostrarSidebar")
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "icon-menu",
                  staticStyle: { color: "#00BFA5" }
                })
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }