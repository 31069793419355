let routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    noLoginRequerido: true,
  },
  {
    path: "/roles/:rolID/pantallas/:id/permisos/",
    name: "Permisos de Rol",
    component: () => import("@/views/roles/permisos"),
    loginRequerido: true,
  },
  {
    path: "/roles/:id/pantallas",
    name: "Pantallas de Rol",
    component: () => import("@/views/roles/pantallas"),
    loginRequerido: true,
  },
  // Pantallas beneficiarios
  {
    path: "/beneficiario/nuevoBeneficiario",
    name: "Crear Beneficiario",
    component: () => import("@/views/beneficiarios/nuevo"),
    code: "VA_CAT_PRO",
    loginRequerido: true,
    meta: {
      layout: "logged_web",
    },
  },
  {
    path: "/beneficiario/modificarBeneficiario/:id",
    name: "Modificar Beneficiario",
    component: () => import("@/views/beneficiarios/modificar"),
    code: "VA_CAT_PRO",
    loginRequerido: true,
    meta: {
      layout: "logged_web",
    },
  },
  // Pantallas centros
  {
    path: "/centro/nuevoCentro",
    name: "Crear Centro",
    component: () => import("@/views/centros/nuevo"),
    code: "VA_CAT_PRO",
    loginRequerido: true,
    meta: {
      layout: "logged_web",
    },
  },
  {
    path: "/centro/modificarCentro/:id",
    name: "Modificar Centro",
    component: () => import("@/views/centros/modificar"),
    code: "VA_CAT_PRO",
    loginRequerido: true,
    meta: {
      layout: "logged_web",
    },
  },
];

export default routes;
