var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tituloPagina" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { display: "flex" }, attrs: { xs: 24, md: 12 } },
            [
              !_vm.$store.state.sidebar
                ? _c(
                    "div",
                    {
                      staticClass: "botonSideBar",
                      attrs: { id: "mostrarSidebar" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.hide,
                              expression: "hide"
                            }
                          ],
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.$store.commit("mostrarSidebar")
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-menu",
                            staticStyle: { color: "#00bfa5" }
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("antesTitulo"),
              _c("div", { staticClass: "textoTopBar" }, [
                _c(
                  "h1",
                  {
                    staticClass: "tituloPaginaTexto",
                    staticStyle: { color: "#edf0f7", "padding-top": "2px" }
                  },
                  [
                    _c("i", {
                      class: _vm.icono,
                      staticStyle: {
                        color: "#edf0f7",
                        "margin-right": "6px",
                        "padding-left": "4px"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.nombrePagina) + " ")
                  ]
                )
              ]),
              _vm._t("operaciones", [
                _vm.textoBoton
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "auto", "margin-right": "0px" },
                        attrs: { type: "text", icon: "el-icon-plus" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("botonNuevoCliqueado")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.textoBoton))]
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }