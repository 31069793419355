var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "el-container",
        [
          _c("Sidebar", { attrs: { id: "sidebar" } }),
          _c("OcultarSidebar"),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                { attrs: { id: "main" } },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade-quick", mode: "out-in" } },
                    [_c("router-view")],
                    1
                  )
                ],
                1
              ),
              _c("el-footer", { attrs: { id: "footer", height: "25px" } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "http://macamedia.com.ar", target: "_blank" }
                  },
                  [_vm._v("2021 Macamedia")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }