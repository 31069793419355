var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c("el-date-picker", {
        ref: "datepicker",
        staticStyle: { width: "100%" },
        attrs: {
          type: "daterange",
          format: "dd/MM/yyyy",
          "value-format": "yyyy-MM-dd",
          "picker-options": _vm.fechaOpciones,
          disabled: _vm.disabled,
          "range-separator": "-",
          "start-placeholder": "Desde",
          "end-placeholder": "Hasta",
          size: _vm.size
        },
        on: { change: _vm.onFechaSeleccionada },
        model: {
          value: _vm.fechaSeleccionada,
          callback: function($$v) {
            _vm.fechaSeleccionada = $$v
          },
          expression: "fechaSeleccionada"
        }
      }),
      _c("div", { staticClass: "slot-wrapper" }, [_vm._t("append")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }