<template>
  <div style="display: flex">
    <el-date-picker
      ref="datepicker"
      v-model="fechaSeleccionada"
      type="date"
      format="dd/MM/yyyy"
      value-format="yyyy-MM-dd"
      :placeholder="placeholder"
      :picker-options="fechaOpciones"
      @change="$emit('input', fechaSeleccionada)"
      :size="size"
      style="width: 100%"
      :disabled="disabled"
    ></el-date-picker>
    <div class="slot-wrapper">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<style scoped>
.slot-wrapper > * {
  margin-left: 10px;
}
</style>

<script>
export default {
  name: "maca-fecha",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: ""
    },
    pasadoHabilitado: {
      type: Boolean,
      default: true
    },
    futuroHabilitado: {
      type: Boolean,
      default: true
    },
    // Seleccionar fecha actual al iniciar
    fechaActual: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fechaSeleccionada: "",
      fechaOpciones: {
        shortcuts: [
          {
            text: "Hoy",
            onClick: picker => {
              picker.$emit(
                "pick",
                this.$moment(new Date()).format("YYYY-MM-DD")
              );
            }
          },
          {
            text: "Mañana",
            onClick: picker => {
              picker.$emit(
                "pick",
                this.$moment(new Date())
                  .add(1, "day")
                  .format("YYYY-MM-DD")
              );
            }
          },
          {
            text: "La semana que viene",
            onClick: picker => {
              picker.$emit(
                "pick",
                this.$moment(new Date())
                  .add(1, "week")
                  .format("YYYY-MM-DD")
              );
            }
          }
        ]
      }
    };
  },
  mounted() {
    if (this.fechaActual) {
      this.fechaSeleccionada = this.$moment(new Date()).format("YYYY-MM-DD");
      this.$emit("input", this.fechaSeleccionada);
    }

    this.fechaOpciones.disabledDate = this.diaDeshabilitado;
  },
  methods: {
    diaDeshabilitado(time) {
      if (!this.pasadoHabilitado) {
        return time.getTime() < Date.now() - 3600 * 1000 * 24;
      }
      if (!this.futuroHabilitado) {
        return time.getTime() > Date.now();
      }

      return false;
    },

    clear() {
      this.fechaSeleccionada = null;
      this.$emit("input", null);
    },

    focus() {
      this.$refs.datepicker.$refs.input.focus();
    }
  },
  watch: {
    value: {
      handler: function() {
        this.fechaSeleccionada = this.value;
      }
    }
  }
};
</script>
