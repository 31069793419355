<template>
  <div id="sidebarMenuButton">
    <div v-if="$store.state.sidebar" id="ocultarSidebar">
      <el-button type="text" @click="$store.commit('ocultarSidebar')">
        <i class="icon-menu" style="color: #00BFA5"></i>
      </el-button>
    </div>
    <div v-if="!$store.state.sidebar" id="mostrarSidebar">
      <el-button type="text" @click="$store.commit('mostrarSidebar')">
        <i class="icon-menu" style="color: #00BFA5"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#sidebarMenuButton {
  z-index: 3;
}

/**/

#ocultarSidebar {
  transform: translate(-45px, 10px);
  width: 0px;
  height: 0px;
}

#ocultarSidebar .el-button {
  position: relative;
}

#ocultarSidebar i {
  font-size: 25px;
}

/**/

#mostrarSidebar {
  transform: translate(-53px, 10px);
  width: 0px;
  height: 0px;
}

#mostrarSidebar .el-button {
  position: relative;
}

#mostrarSidebar i {
  font-size: 25px;
}
</style>
