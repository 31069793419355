var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: _vm.width,
        "before-close": _vm.beforeClose
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", { staticClass: "estiloTituloModal" }, [
        _vm._v(_vm._s(_vm.titulo))
      ]),
      _c(
        "div",
        {
          key: _vm.contentKey,
          staticStyle: { margin: "30px", "padding-top": "7px" }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }