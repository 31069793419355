var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "margin-top: 15px; height: " +
        _vm.altura +
        ";overflow:scroll;overflow-x:hidden;"
    },
    _vm._l(_vm.value, function(item, i) {
      return _c(
        "el-row",
        { key: i, attrs: { type: "flex", align: "middle" } },
        [
          _c(
            "el-col",
            { staticStyle: { height: "100%" }, attrs: { span: 2 } },
            [
              _c("i", {
                class: _vm.icono,
                style:
                  "background: " +
                  _vm.color +
                  ";color: " +
                  _vm.invertColor(_vm.color, 1) +
                  "; padding:5px; border-radius: 50%; margin: auto;"
              })
            ]
          ),
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _vm._t(
                "default",
                [
                  _vm._v(
                    _vm._s(typeof item === "object" ? item.nombre : item) + " "
                  )
                ],
                { item: item, index: i }
              )
            ],
            2
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c("el-button", {
                staticStyle: { padding: "2px" },
                attrs: { type: "danger", icon: "el-icon-close" },
                on: {
                  click: function() {
                    _vm.value.splice(i, 1)
                    _vm.$emit("input", _vm.value)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }