var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contenedor-tabla" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.cargando,
              expression: "cargando"
            }
          ],
          attrs: {
            data: _vm.tableData,
            stripe: "",
            rowClassName: _vm.rowClassName
          }
        },
        [
          _vm._t("default"),
          _c("template", { slot: "append" }, [_vm._t("append")], 2)
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c("el-pagination", {
            staticStyle: { margin: "auto" },
            attrs: {
              "current-page": _vm.paginationProps.pagActual,
              "page-sizes": _vm.paginationProps.pageSizes,
              "page-size": _vm.paginationProps.pagTotal,
              layout: _vm.paginationProps.layout,
              total: _vm.paginationProps.pagTotalItems
            },
            on: {
              "size-change": _vm.paginationProps.pageSizes,
              "current-change": _vm.cambiarPagina
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }