<template>
  <div>
    <el-upload
      ref="archivo"
      drag
      action
      :auto-upload="false"
      :on-change="imagenCambiada"
      :on-remove="imagenCambiada(null, [])"
      :multiple="false"
      :accept="fileTypes"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Suelta tus archivos aquí o
        <em>haz clic para cargar</em>
      </div>
      <div slot="tip" class="el-upload__tip">
        <span v-if="tipo == 'imagen'">Suba una imagen JPG o PNG.</span>
        <span v-if="tipo == 'excel'">Sólo archivos de Excel.</span>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    tipo: {
      type: String,
      default: "imagen"
    }
  },
  data() {
    return {
      imagen: null
    };
  },
  computed: {
    fileTypes() {
      let types = null;

      if (this.tipo == "imagen") {
        types = ".jpg, .png, .webp";
      }
      if (this.tipo == "excel") {
        types = ".xlsx";
      }

      if (types != null) {
        return types;
      } else {
        return "*";
      }
    }
  },
  methods: {
    imagenCambiada(nuevaImagen, filelist) {
      while (filelist.length > 1) {
        filelist.splice(0, 1);
      }
      this.imagen = nuevaImagen;

      this.$emit("input", nuevaImagen);
    }
  },
  watch: {
    value() {
      this.imagen = this.value;

      if (this.value == null) {
        this.$refs.archivo.clearFiles();
      } else {
        //TODO: actualizar cuando el archivo no es nulo;
      }
    }
  }
};
</script>

<style></style>
