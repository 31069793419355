// Se usa AXIOS para los request
const axios = require("axios");

import router from "@/router";
import store from "@/store";

//

// Importar Vue para mostrar alertas
import Vue from "vue";

// Obtener URL de API
const APIURL = process.env.VUE_APP_API_URL;

// Tiempo de timeout para pedidos GET y POST
const defaultGetTimeout = 20000;
const defaultPostTimeout = 30000;
//

// Array de peticiones a cancelar una vez que se abandona la vista.
let cancelStack = [];

//////////////////////////////////////////////////////////////////////

// ***
// Cancelar todas las peticiones en cancelStack
// ***
function doCancelStack() {
  cancelStack.forEach((cancelSource) => {
    cancelSource.cancel(
      "Requests cancelado por cambio de página. " + cancelSource.name
    );
  });
}

// ***
// Sacar una consulta de cancelStack. Se llama cuando la petición terminó
// ***
function removeFromCancelStack(name) {
  for (let index = 0; index < cancelStack.length; index++) {
    const cancelSource = cancelStack[index];

    if (cancelSource.name == name) {
      cancelStack.splice(index, 1);
    }
  }
}

// ***
// Función pública para descargar un archivo
// ***
function downloadFile(url, token = "", nombre = "file.txt") {
  const httpClient = axios.create();
  httpClient.defaults.headers = {
    Authorization: "Bearer " + token,
  };

  return httpClient
    .get(APIURL + url, {
      responseType: "blob", // important
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre);
      document.body.appendChild(link);
      link.click();

      return true;
    });
}

// ***
// Función pública para hacer una petición GET
// ***
function get(
  url,
  urlParams = {},
  timeout = defaultGetTimeout,
  mensajeError = false,
  cancelOnRouteChange = true
) {
  // Crear un cliente AXIOS para hacer la consulta y
  //  obtener un token para poder cancelarla
  const httpClient = axios.create();
  let cancelTokenSource = null;
  let clientParams = {};

  // Si la petición se puede cancelar, obtener token de cancelación
  if (cancelOnRouteChange) {
    const cancelToken = axios.CancelToken;
    cancelTokenSource = cancelToken.source();

    cancelTokenSource.name = url;

    cancelStack.push(cancelTokenSource);
    clientParams.cancelToken = cancelTokenSource.token;
  }
  //

  // Timeout desactivado con 0.
  // Se desactiva cuando hay que subir archivos.
  if (timeout != 0) {
    httpClient.defaults.timeout = timeout;
  }

  // Si se proporsionó token, agregar header a petición
  if (store.state.token != null) {
    httpClient.defaults.headers = {
      Authorization: "Bearer " + store.state.token,
    };
  }

  httpClient.defaults.params = urlParams;

  // Ejecutar petición y devolver promesa con datos
  return httpClient
    .get(APIURL + url, clientParams)
    .then(function(response) {
      // Petición finalizada con éxito
      let resultado = {
        estado: response.data.estado,
        mensaje: response.data.mensaje,
        excepcion: response.data.excepcion,
        datosPaginado: response.data.datos
          ? {
              pagTotal: response.data.datos.pagTotal,
              pagActual: response.data.datos.pagActual,
              pagTotalItems: response.data.datos.pagTotalItems,
            }
          : null,
        datos: response.data.datos ? response.data.datos.datos : null,
      };

      // Si la petición es cancelable quiere decir que está en la lista para ser
      // cancelada al cambiar de vista. La petición terminó, así que sacarla
      // de la lista
      if (cancelTokenSource != null) {
        removeFromCancelStack(cancelTokenSource.name);
      }
      //

      // Comprobar si hubo errores de API. Llevar a login si hay error de Token.
      // Si hay errores, retorna promesa con datos vacios.
      if (resultado.estado < 1) {
        // Error de token, llevar a login
        if (
          resultado.estado == -100 ||
          resultado.estado == -110 ||
          resultado.estado == -120
        ) {
          store.commit("logout");
          router.replace("/login");
        } else {
          if (mensajeError) {
            return resultado;
          }
        }
        return null;
      }
      //

      return resultado;
    })
    .catch(function(error) {
      // Hubo un error al realizar la petición

      // Si la petición es cancelable quiere decir que está en la lista para ser
      // cancelada al cambiar de vista. La petición terminó, así que sacarla
      // de la lista
      if (cancelTokenSource != null) {
        removeFromCancelStack(cancelTokenSource.name);
      }

      // Si es un error desconocido, no devolver nada
      return null;
    });
}

// ***
// Función pública para hacer una petición POST
// ***
function post(url, params = {}, timeout = defaultPostTimeout) {
  // Crear un cliente AXIOS para hacer la consulta
  const httpClient = axios.create();

  // Timeout desactivado con 0.
  // Se desactiva cuando hay que subir archivos.
  if (timeout != 0) {
    httpClient.defaults.timeout = timeout;
  }

  // Si se proporsionó token, agregar header a petición
  if (store.state.token != null) {
    httpClient.defaults.headers = {
      Authorization: "Bearer " + store.state.token,
    };
  }
  //

  // Realizar petición y devolver promesa con datos
  return httpClient
    .post(APIURL + url, params)
    .then(function(response) {
      // Petición finalizada con éxito
      let resultado = {
        estado: response.data.estado,
        mensaje: response.data.mensaje,
        excepcion: response.data.excepcion,
        datos: response.data.datos,
      };

      // En una version de la API los posts devuelven los datos en .datos.datos
      if (response.data.datos != null && response.data.datos.datos != null) {
        resultado.datos = response.data.datos.datos;
      }

      return resultado;
    })
    .catch(function(error) {
      // Hubo un error al realizar la petición
      return {
        estado: 0,
        mensaje: "Ocurrió un error al procesar POST en frontend",
      };
    });
}

// Cancelar peticiones cada vez que se cambie de ruta
router.beforeEach((to, from, next) => {
  doCancelStack();
  next();
});
//

export default {
  APIURL,
  doCancelStack,
  get,
  post,
  defaultGetTimeout,
  defaultPostTimeout,
  cancelStack,
  downloadFile,
};
