<template>
  <div style="width: 100%;">
    <div style="margin-bottom: 10px; margin-top: 10px">
      <div style="display: flex;">
        <div style="margin:auto; margin-left: 0px;">
          <span v-text="mensaje"></span>
        </div>
        <div style="margin: auto: margin-right: 0px; margin-left: 0px;">
          <el-button style="position: relative; top: -25px" type="text" @click="cerrar">
            <i class="el-icon-close" style="font-size: 16px; color: #555"></i>
          </el-button>
        </div>
      </div>
    </div>
    <el-collapse v-model="datosVisible" class="collapse-error">
      <el-collapse-item title="Ver Datos" :name="1">
        <div style="margin-top: 10px; max-height: 300px; overflow: auto">
          <div>
            <code v-text="handler.url"></code>
            <br />
            <code>---</code>
            <br />
            <code>Código: {{ handler.estado }}</code>
            <br />
            <code>---</code>
            <br />
            <code>Mensaje: {{ handler.mensaje }}</code>
            <br />
            <code>---</code>
            <br />
            <code>Excepción: {{handler.excepcion}}</code>
            <br />
            <code>---</code>
            <br />
            <code>Datos Enviados:</code>
            <br />
            <code v-text="handler.params"></code>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style>
.collapse-error > .el-collapse-item > div > .el-collapse-item__header {
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.collapse-error > .el-collapse-item > .el-collapse-item__wrap {
  background-color: rgba(255, 255, 255, 0.5);
}
.mensajeError {
  background-color: #fef0f0;
  border-color: #fde2e2;
  max-width: 500px;
}
</style>

<script>
import { Message } from "element-ui";

export default {
  props: ["mensaje", "handler"],
  data() {
    return {
      datosVisible: []
    };
  },
  methods: {
    cerrar() {
      this.$message.closeAll();
    }
  }
};
</script>
