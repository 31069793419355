<template>
  <div
    :style="
      'margin-top: 15px; height: ' +
        altura +
        ';overflow:scroll;overflow-x:hidden;'
    "
  >
    <el-row v-for="(item, i) in value" :key="i" type="flex" align="middle">
      <el-col :span="2" style="height: 100%"
        ><i
          :class="icono"
          :style="
            'background: ' +
              color +
              ';color: ' +
              invertColor(color, 1) +
              '; padding:5px; border-radius: 50%; margin: auto;'
          "
        ></i
      ></el-col>
      <el-col :span="20">
        <slot :item="item" :index="i"
          >{{ typeof item === "object" ? item.nombre : item }}
        </slot>
      </el-col>
      <el-col :span="2">
        <el-button
          type="danger"
          icon="el-icon-close"
          style="padding:2px"
          @click="
            () => {
              value.splice(i, 1);
              $emit('input', value);
            }
          "
        ></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    icono: {
      type: String,
      default: "el-icon-check"
    },
    altura: {
      type: String,
      default: "200px"
    },
    color: {
      type: String,
      default: "#66B1FF"
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value() {
      console.log(this.value);
    }
  },
  methods: {
    invertColor(hex, bw) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + padZero(r) + padZero(g) + padZero(b);
    }
  }
};
</script>

<style></style>
