var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px", "margin-top": "10px" } },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticStyle: { margin: "auto", "margin-left": "0px" } },
              [_c("span", { domProps: { textContent: _vm._s(_vm.mensaje) } })]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  margin: "auto: margin-right: 0px",
                  "margin-left": "0px"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { position: "relative", top: "-25px" },
                    attrs: { type: "text" },
                    on: { click: _vm.cerrar }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: { "font-size": "16px", color: "#555" }
                    })
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "el-collapse",
        {
          staticClass: "collapse-error",
          model: {
            value: _vm.datosVisible,
            callback: function($$v) {
              _vm.datosVisible = $$v
            },
            expression: "datosVisible"
          }
        },
        [
          _c("el-collapse-item", { attrs: { title: "Ver Datos", name: 1 } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "10px",
                  "max-height": "300px",
                  overflow: "auto"
                }
              },
              [
                _c("div", [
                  _c("code", {
                    domProps: { textContent: _vm._s(_vm.handler.url) }
                  }),
                  _c("br"),
                  _c("code", [_vm._v("---")]),
                  _c("br"),
                  _c("code", [_vm._v("Código: " + _vm._s(_vm.handler.estado))]),
                  _c("br"),
                  _c("code", [_vm._v("---")]),
                  _c("br"),
                  _c("code", [
                    _vm._v("Mensaje: " + _vm._s(_vm.handler.mensaje))
                  ]),
                  _c("br"),
                  _c("code", [_vm._v("---")]),
                  _c("br"),
                  _c("code", [
                    _vm._v("Excepción: " + _vm._s(_vm.handler.excepcion))
                  ]),
                  _c("br"),
                  _c("code", [_vm._v("---")]),
                  _c("br"),
                  _c("code", [_vm._v("Datos Enviados:")]),
                  _c("br"),
                  _c("code", {
                    domProps: { textContent: _vm._s(_vm.handler.params) }
                  })
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }