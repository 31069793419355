import Vue from "vue";

// Para los input de telefonos
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

// Moment
const moment = require("moment");
Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale("es");

// Mapas de macamedia
// antes de usar ejecutar "yarn add vue2-leaflet" y "yarn add leaflet"
import "leaflet/dist/leaflet.css";
import "../macamedia/mapa";
