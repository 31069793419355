<template>
  <div class="tituloPagina">
    <el-row>
      <el-col :xs="24" :md="12" style="display: flex">
        <div
          v-if="!$store.state.sidebar"
          id="mostrarSidebar"
          class="botonSideBar"
        >
          <el-button
            type="text"
            @click="$store.commit('mostrarSidebar')"
            v-click-outside="hide"
          >
            <i class="icon-menu" style="color: #00bfa5"></i>
          </el-button>
        </div>
        <slot name="antesTitulo"> </slot>
        <div class="textoTopBar">
          <h1
            class="tituloPaginaTexto"
            style="color: #edf0f7; padding-top: 2px"
          >
            <i
              :class="icono"
              style="color: #edf0f7; margin-right: 6px; padding-left: 4px"
            ></i>
            {{ nombrePagina }}
          </h1>
        </div>

        <slot name="operaciones"
          ><el-button
            v-if="textoBoton"
            style="margin: auto; margin-right: 0px"
            type="text"
            icon="el-icon-plus"
            @click="$emit('botonNuevoCliqueado')"
            >{{ textoBoton }}</el-button
          ></slot
        >
      </el-col>
    </el-row>
  </div>
</template>

<style>
.tituloPagina .el-row > div {
  position: relative;
  height: 70px;
  background: #223242;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% + 30px);
  margin-top: -25px;
  margin-left: -20px;
  margin-right: -20px;
}
.tituloPaginaTexto {
  font-size: 1.5rem;
  margin: 5px;
  display: flex;
  padding-top: 5px;
}
.tituloPagina .el-row > div i {
  margin-right: 10px;
  font-size: 22px;
  display: flex;
}

.ocultarMostrarItem {
  display: block;
}

.botonSideBar {
  margin: 0px;
  padding-top: 0.6rem;
  padding-left: 0.5rem;
  background-color: #2c3e50;
}

.textoTopBar {
  margin-top: 1rem;
  margin-left: 1rem;
}

@media screen and (max-device-width: 370px) {
  .tituloPagina .el-row > div {
    padding-right: 1.5rem;
  }
}

@media screen and (max-device-width: 470px) {
  .ocultarMostrarItem {
    display: none;
  }
  .botonNotificaciones {
    margin-left: auto !important;
  }
  .tituloPaginaTexto {
    font-size: 1rem;
  }
  .textoTopBar {
    margin-left: 0px;
  }
}

@media screen and (max-device-width: 650px) {
  .tituloPaginaTexto {
    font-size: 1rem;
  }
}
</style>

<script>
import ClickOutside from "vue-click-outside";
export default {
  props: {
    icono: {
      type: String,
      default: "el-icon-postcard",
    },
    nombrePagina: {
      type: String,
      default: "Peliculas",
    },
    textoBoton: {
      type: String,
      default: null,
    },
    botonNuevoHabilitado: {
      type: Boolean,
      default: true,
    },
  },
 
};
</script>

<style></style>
