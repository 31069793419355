<template>
  <div>
    <maca-select-box
      ref="selectBox"
      @opcion-seleccionada="datos => $emit('input', datos)"
      v-model="opcionSeleccionada"
      :url="urlPersona"
      :item-label="['apellido', 'nombre', ['dni']]"
      :necesitaParams="true"
      :permitirInput="true"
      itemValue="id"
      :placeholder="placeholder"
      :clearable="true"
      :getParams="getParams"
      :disabled="disabled"
      :size="size"
    ></maca-select-box>
  </div>
</template>

<script>
export default {
  name: "maca-select-box-persona",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium"
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      placeholder: "Ingrese el Nombre o DNI",
      opcionSeleccionada: null,
      getParams: this.getParamsPersona,
      urlPersona: "/persona/buscar"
    };
  },
  methods: {
    getParamsPersona(query) {
      if (!query || query == "") {
        return null;
      }

      let inputEsNumero = /^\d+$/.test(query);
      let params = null;

      if (inputEsNumero) {
        params = {
          dni: query
        };
      } else {
        params = {
          nombre: query
        };
      }

      return params;
    }
  },
  watch: {
    value() {
      this.opcionSeleccionada = this.value;
    }
  }
};
</script>
