import store from "@/store";

let moment = require("moment");
moment.locale("es");

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY hh:mm
function formatearFechaHora(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);

  if (!date.isValid()) {
    return "";
  }

  return moment(fecha).format("DD/MM/YYYY hh:mm");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY hh:mm
function formatearHoraDeFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);

  if (!date.isValid()) {
    return "";
  }

  return moment(fecha).format("hh:mm");
}

// Dada una hora de formato hh:mm:ss, devolver hh:mm
function formatearHora(hora) {
  if (hora == null) {
    return;
  }
  return hora.substr(0, 5);
}

// Activar animacion en eventos click
function toggleAnimation(evento, clase) {
  // Agregar clase al elemento. Si la clase ya estaba, toggle devuelve false
  // y la elimina
  let anim = evento.target.classList.toggle(clase);

  // si la clase ya estaba, agregarla otra vez para volver a reproducir la anim
  // esperar un poco hasta que vue termine de ejecutar la instruccion anterior
  if (!anim) {
    setTimeout(() => {
      anim = evento.target.classList.toggle(clase);
    }, 50);
  }
}

function usuarioEs(rol) {
  let esRol = false;

  store.state.usuario.roles.forEach(rol => {
    if (RolesUtils.checkRol(rol)) {
      esRol = true;
    }
  });

  return esRol;
}

export default {
  formatearFecha,
  formatearFechaHora,
  formatearHora,
  formatearHoraDeFecha,
  toggleAnimation,
  usuarioEs
};
