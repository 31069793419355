import Vue from "vue";
import Router from "vue-router";

import OtherRoutes from "./otherRoutes";
import SidebarRoutes from "./sidebarRoutes";
import MacaRouter from "@/macamedia/router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: MacaRouter.armarRoutes(OtherRoutes, SidebarRoutes)
});
