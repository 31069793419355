import store from "@/store";

const CONDITIONS = {
  authenticated: "AUTHENTICATED",
  notAuthenticated: "NOT_AUTHENTICATED",
  permisosValidos: "PERMISOSVALIDOS"
};

function checkAuthenticated() {
  if (store.state.usuario != null) {
    return true;
  }
  return false;
}

function checkNotAuthenticated() {
  if (store.state.usuario == null) {
    return true;
  }
  return false;
}

function checkPermisosValidos(to) {
  let valido = true;

  // Ver pantalla inicio
  if (to.path == "/") {
    return true;
  }

  store.state.permisos.forEach(permisoUsuario => {
    if (permisoUsuario.tipo == "V" && permisoUsuario.pantalla == to.name) {
      valido = true;
    }
  });

  return valido;
}

function validateAccess(condition, params = null) {
  let accessGranted = false;

  switch (condition) {
    case CONDITIONS.authenticated:
      accessGranted = checkAuthenticated();
      break;
    case CONDITIONS.notAuthenticated:
      accessGranted = checkNotAuthenticated();
      break;
    case CONDITIONS.permisosValidos:
      accessGranted = checkPermisosValidos(params);
      break;

    default:
      break;
  }

  return accessGranted;
}

export default { validateAccess, CONDITIONS };
