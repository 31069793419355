export default {
  //// "_" se usa para eventos generales
  // Registros
  _actualizacion: "actualizacion",
  _pasoEstado: "paso-estado",

  // Tablas
  _actualizarTabla: "actualizar-tabla" // se usa cuando no se necesita un bus de eventos

  //// Aquí van eventos especificos por bus
  /*
  ventas: {
      especifico: 'especifico'
  }
  */
};
