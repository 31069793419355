<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    :width="width"
    :before-close="beforeClose"
  >
    <span class="estiloTituloModal">{{ titulo }}</span>
    <div :key="contentKey" style="margin: 30px; padding-top: 7px">
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "maca-modal",
  props: {
    titulo: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "1100px", //900px default
    },
    impedirClose: {
      type: Boolean,
      default: false,
    },
    mensajeConfirmacion: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      contentKey: 0,
    };
  },
  methods: {
    abrir() {
      this.contentKey += 1;
      this.visible = true;
    },
    cerrar() {
      this.visible = false;
    },
    beforeClose(done) {
      if (this.impedirClose) {
        return;
      }

      if (this.mensajeConfirmacion) {
        this.$confirm("¿Está seguro de que desea cerrar el formulario?")
          .then((_) => {
            done();
          })
          .catch(() => {});
      } else {
        done();
      }
    },
  },
};
</script>
