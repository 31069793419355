<template>
  <div style="display: flex">
    <maca-select-box
      ref="pais"
      placeholder="País"
      v-model="pais"
      :url="urlPais"
      style="margin-right: 10px"
      :disabled="disabled"
      @opcion-seleccionada="paisSeleccionado"
    ></maca-select-box>
    <maca-select-box
      ref="provincia"
      placeholder="Provincia"
      v-model="provincia"
      :url="urlProvincia"
      style="margin-right: 10px"
      :disabled="disabled || pais == null"
      @opcion-seleccionada="provinciaSeleccionada"
    ></maca-select-box>
    <maca-select-box
      ref="localidad"
      placeholder="Localidad"
      v-model="localidad"
      :url="urlLocalidad"
      :disabled="disabled || provincia == null"
      @opcion-seleccionada="localidadSeleccionada"
    ></maca-select-box>
  </div>
</template>

<script>
export default {
  name: "ubicacion",
  props: {
    usarUbicacionUsuario: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pais: null,
      provincia: null,
      localidad: null,

      urlPais: "/pais/obtenerTodos",
      urlProvincia: "", // se llena despues
      urlLocalidad: "" // se llena despues
    };
  },
  created() {
    if (this.usarUbicacionUsuario) {
      this.pais = {
        nombre: this.$store.state.usuario.pais,
        id: this.$store.state.usuario.paisID
      };
      this.provincia = {
        nombre: this.$store.state.usuario.provincia,
        id: this.$store.state.usuario.provinciaID
      };
      this.localidad = {
        nombre: this.$store.state.usuario.localidad,
        id: this.$store.state.usuario.localidadID
      };
      this.verificarCampos();
    } else if (this.value != null) {
      this.pais = {
        nombre: this.value.pais.nombre,
        id: this.value.pais.id
      };
      this.provincia = {
        nombre: this.value.provincia.nombre,
        id: this.value.provincia.id
      };
      this.localidad = {
        nombre: this.value.localidad.nombre,
        id: this.value.localidad.id
      };
      this.verificarCampos();
    }
  },
  methods: {
    verificarCampos() {
      if (this.pais != null) {
        this.urlProvincia = "/provincia/obtenerTodos?paisID=" + this.pais.id;
      }
      if (this.provincia != null) {
        this.urlLocalidad =
          "/localidad/obtenerTodos?provinciaID=" + this.provincia.id;
      }

      this.$nextTick(() => {
        if (this.pais != null) {
          this.$refs.provincia.recargar();
        }
        if (this.provincia != null) {
          this.$refs.localidad.recargar();
        }
      });
    },
    paisSeleccionado() {
      this.provincia = null;
      this.localidad = null;
      this.verificarCampos();
    },
    provinciaSeleccionada() {
      this.localidad = null;
      this.verificarCampos();
    },
    localidadSeleccionada() {
      this.$nextTick(() => {
        this.$emit("input", {
          pais: this.pais,
          provincia: this.provincia,
          localidad: this.localidad
        });
      });
    }
  },
  watch: {
    value() {
      this.pais = {
        nombre: this.value.pais.nombre,
        id: this.value.pais.id
      };
      this.provincia = {
        nombre: this.value.provincia.nombre,
        id: this.value.provincia.id
      };
      this.localidad = {
        nombre: this.value.localidad.nombre,
        id: this.value.localidad.id
      };

      this.verificarCampos();
    }
  }
};
</script>
