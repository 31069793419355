import Vue from "vue";

import common from "./common.js";
import eventStrings from "./event_string.js";
import api from "./api";
import ListJsonManager from "./listJsonManager";

Vue.prototype.$maca = {
  common: common,
  eventStrings: eventStrings,
  api: api,
  listJsonManager: ListJsonManager,
  esMovil: () => {
    return window.outerWidth <= 768;
  }
};
