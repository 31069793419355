var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "archivo",
          attrs: {
            drag: "",
            action: "",
            "auto-upload": false,
            "on-change": _vm.imagenCambiada,
            "on-remove": _vm.imagenCambiada(null, []),
            multiple: false,
            accept: _vm.fileTypes
          }
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(" Suelta tus archivos aquí o "),
            _c("em", [_vm._v("haz clic para cargar")])
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip"
            },
            [
              _vm.tipo == "imagen"
                ? _c("span", [_vm._v("Suba una imagen JPG o PNG.")])
                : _vm._e(),
              _vm.tipo == "excel"
                ? _c("span", [_vm._v("Sólo archivos de Excel.")])
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }