let routes = [
  {
    path: "/",
    name: "Inicio",
    icon: "icon-home",
    component: () => import("@/views/home"),
  },
  {
    path: "/viajes",
    name: "Viajes",
    icon: "el-icon-guide",
    component: () => import("@/views/viajes"),
  }, 
   {
    path: "/liquidaciones",
    name: "Liquidaciones",
    icon: "el-icon-money",  
    component: () => import("@/views/liquidaciones"),
  },
  {
    path: "/centros",
    name: "Centros",
    icon: "el-icon-office-building",
    component: () => import("@/views/centros"),
  },
  {
    path: "/transportistas",
    name: "Transportistas",
    icon: "el-icon-truck",
    component: () => import("@/views/transportistas"),
  },
  {
    path: "/beneficiarios",
    name: "Beneficiarios",
    icon: "icon-user",
    component: () => import("@/views/beneficiarios"),
  },

  {
    path: "/ajustes",
    name: "Ajustes",
    icon: "icon-cog",
    children: [
      {
        path: "/usuarios",
        name: "Usuarios",
        icon: "icon-user",
        component: () => import("@/views/usuarios"),
      },
      {
        path: "/roles",
        name: "Roles",
        icon: "icon-suitcase",
        component: () => import("@/views/roles"),
      },

      {
        path: "/arbol",
        name: "Arbol de Ubicaciones",
        icon: "el-icon-map-location", 
        component: () => import("@/views/arbol"),
      },

      {
        path: "/tiposBeneficiario",
        name: "Tipos de Beneficiarios",
        icon: "el-icon-s-custom", 
        component: () => import("@/views/tiposBeneficiario"),
      },
      {
        path: "/costosKm",
        name: "Costos Km",
        icon: "el-icon-money",  
        component: () => import("@/views/costosKm"),
      },


      {
        path: "/variables",
        name: "Variables",
        icon: "icon-cog",
        component: () => import("@/views/variables"),
      },

     
    ],
  },
];

export default routes;
